





















import Vue from "vue";
import NavLink from "@/components/navbar/navLink.vue";

export default Vue.extend({
  name: "NavBar",
  components: { NavLink },
});







import Vue from "vue";

export default Vue.extend({
  name: "NavLink",
  props: {
    text: {
      type: String
    },
    link: {
      type: String
    },
    isDropdown: {
      type: String,
      default: "false"
    }
  },
});

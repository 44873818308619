import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Accueil',
    component: () => import('../views/Accueil.vue'),
  },
  {
    path: '/histoire',
    name: 'Histoire',
    component: () => import('../views/Histoire.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/projets',
    name: 'Projets',
    component: () => import('../views/Projets.vue'),
  },
  {
    path: '/equipe',
    name: 'Notre Equipe',
    component: () => import('../views/Equipe.vue'),
  },
  {
    path: '/cgu/:name',
    name: 'CGU',
    component: () => import('../views/Mentions/CGU.vue'),
    props: true
  },
  {
    path: '/cgv/:name',
    name: 'CGV',
    component: () => import('../views/Mentions/CGV.vue'),
    props: true
  },
  {
    path: '/rgpd',
    name: 'RGPD',
    component: () => import('../views/Mentions/RGPD.vue'),
  },
  {
    path: '*',
    name: 'Erreur 404',
    component: () => import('../views/404.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Hydeos | " + to.name
  next()
})

export default router;













import Vue from "vue";
import Hotjar from 'vue-hotjar'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Loader from "@/components/Loader.vue";
import NavBar from "@/components/navbar/navBar.vue";
import Footer from "@/components/Footer.vue";

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Hotjar implementation
Vue.use(Hotjar, {
  id: '2612393' 
})

export default Vue.extend({
  components: { Loader, NavBar, Footer },
  data: () => {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 0);
  },
});
